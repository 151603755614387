/* eslint-disable */
// @flow
import classNames from "classnames";
import React, { useState, useEffect, useMemo, useCallback } from "react";

const fieldStyles = {
  input: {
    color: "#292e33",
    "font-family": "'Noto Sans JP', sans-serif",
    "font-weight": 400,
    "font-size": "18px",
    "line-height": "24px",
  },
  "::placeholder": {
    color: "#979ca0",
  },
  ".invalid .card-icon": {
    color: "#ee4938",
  },
};

export default function CreditCardInputNew({
  onSuccess,
  children,
  handleChange,
  handleSubmit,
  handleSubmitError,
  handleCardAddingStatus,
  handleIsSubmitting,
  handleIsNotSubmitting,
  handleFormIsReady,
  secureReference,
  creditCardToken,
  creditCardUserId,
  submitChildren,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [detailedErrors, setDetailedErrors] = useState({});
  const [isMounted, setIsMounted] = useState(false);

  const hostedFields = useMemo(() => {
    let fields;
    if (ENVIRONMENT === "production") {
      fields = assembly.hostedFields({ environment: "production" });
    } else {
      fields = assembly.hostedFields({ environment: "pre-live" });
    }
    return fields;
  }, []);

  const handleAsmSubmit = useCallback(
    (event) => {
      event.preventDefault();
      // handleIsSubmitting();
      setError(false);
      if (handleCardAddingStatus !== undefined) {
        handleCardAddingStatus(true);
      }

      // console.log(creditCardUserId);
      // console.log('asm submitting')
      // console.log(creditCardToken);
      hostedFields
        .createCardAccount({
          token: creditCardToken,
          user_id: creditCardUserId,
        })
        .then(function (response) {
          // handleIsNotSubmitting();
          handleSubmit(response);
          // const testResponse = {
          //   errors: {
          //     base: ["There was an error creating your card account."],
          //   },
          // };
          // processAssemblyErrors(testResponse);
          // handleSubmitError(event, testResponse);
        })
        .catch(function (response) {
          // handle errors
          // console.log(response);
          // handleIsNotSubmitting();
          DD_LOGS.logger.info("assembly-card-creation-error", {
            env: ENVIRONMENT,
            secure_reference: secureReference,
            error: response.errors,
          });
          processAssemblyErrors(response);
          if (handleSubmitError !== undefined) {
            handleSubmitError(event, response);
          }
        });
    },
    [creditCardToken, creditCardUserId]
  );

  useEffect(() => {
    if (!isMounted) {
      if (!_.isEmpty(creditCardToken) && !_.isEmpty(creditCardUserId)) {
        const cardName = hostedFields.create("cardName", {
          placeholder: "Name on card",
          tabIndex: 1,
          styles: fieldStyles,
        });
        const cardNumber = hostedFields.create("cardNumber", {
          placeholder: "Card number",
          tabIndex: 2,
          styles: fieldStyles,
        });
        const cardExpiry = hostedFields.create("cardExpiry", {
          placeholder: "MM/YY",
          tabIndex: 3,
          styles: fieldStyles,
        });
        const cardCvv = hostedFields.create("cardCvv", {
          placeholder: "CVC",
          tabIndex: 4,
          styles: fieldStyles,
        });

        cardName.on("ready", (event) => {
          cardName.focus();
          handleFormIsReady(true);
        });

        cardName.mount("#card-name-field");
        cardNumber.mount("#card-number-field");
        cardExpiry.mount("#card-expiry-field");
        cardCvv.mount("#card-cvv-field");

        const inputs = [cardName, cardNumber, cardExpiry, cardCvv];

        inputs.forEach(function (field) {
          field.on("change", (event) => {
            const fieldName = event.fieldType;
            const valid = event.valid;
            handleChange(fieldName, valid);
            setFieldErrors((prevState) => ({
              ...prevState,
              [event.fieldType]: event.error ? event.error.message : undefined,
            }));
          });
        });

        setIsMounted(true);
      }
    }
  }, [creditCardToken, creditCardUserId]);

  useEffect(() => {
    if (isMounted) {
      const form = document.getElementById("new-asm-card");

      form.addEventListener("submit", handleAsmSubmit);
      return () => {
        form.removeEventListener("submit", handleAsmSubmit);
      };
    }
  }, [isMounted, handleAsmSubmit]);

  const processAssemblyErrors = (response) => {
    if (response.errors && response.errors.token) {
      setError("Your token is not authorized");
    } else {
      setError("There was an error creating your card account.");
      setDetailedErrors({
        base: response?.errors?.base,
        providerMessage: response?.errors?.provider_response_message,
        providerCode: response?.errors?.provider_response_code,
      });
    }
  };

  const parseDetailedErrors = (detailedErrors) => {
    let errorArray = [];
    if (detailedErrors.base) {
      errorArray.push(detailedErrors.base);
    }
    if (detailedErrors.providerMessage) {
      errorArray.push(detailedErrors.providerMessage);
    }
    errorArray = _.flatten(errorArray);
    return errorArray;
  };

  return (
    <form id="new-asm-card">
      <div className="row">
        <div
          className={classNames("col assembly-card-container border-top border-bottom", {
            error: fieldErrors.cardName,
          })}
        >
          <div
            className={classNames("cardName-container field-container", {
              error: fieldErrors.cardName,
            })}
          >
            {/* <label htmlFor="card-name-field">Cardholder Name</label> */}
            <div id="card-name-field" className="hosted-field"></div>
            {fieldErrors.cardName && <div className="error">{fieldErrors.cardName}</div>}
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={classNames("col assembly-card-container border-bottom", {
            error: fieldErrors.cardNumber,
          })}
        >
          <div
            className={classNames("cardNumber-container field-container", {
              error: fieldErrors.cardNumber,
            })}
          >
            {/* <label htmlFor="card-number-field">Card Number</label> */}
            <div id="card-number-field" className="hosted-field"></div>
            {fieldErrors.cardNumber && <div className="error">{fieldErrors.cardNumber}</div>}
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={classNames("col assembly-card-container col-6 border-bottom", {
            error: fieldErrors.cardExpiry,
          })}
        >
          <div
            className={classNames("cardExpiry-container field-container", {
              error: fieldErrors.cardExpiry,
            })}
          >
            {/* <label htmlFor="card-expiry-field">Expiry Date</label> */}
            <div id="card-expiry-field" className="hosted-field"></div>
            {fieldErrors.cardExpiry && <div className="error">{fieldErrors.cardExpiry}</div>}
          </div>
        </div>
        <div
          className={classNames("col assembly-card-container col-6 border-bottom", {
            error: fieldErrors.cardCvv,
          })}
        >
          <div
            className={classNames("cardCvv-container field-container", {
              error: fieldErrors.cardCvv,
            })}
          >
            {/* <label htmlFor="card-cvv-field">CVV</label> */}
            <div id="card-cvv-field" className="hosted-field"></div>
            {fieldErrors.cardCvv && <div className="error">{fieldErrors.cardCvv}</div>}
          </div>
        </div>
      </div>

      {children}
      {!_.isEmpty(error) && (
        <div className="row">
          <div className="col col-12 form-description text-black with-margin-bottom-0 with-margin-bottom-80">
            <div className="error-box">
              <div className="d-inline-block">
                <h4>Problem with card</h4>
                {!_.isEmpty(detailedErrors) && (
                  <div className="">
                    <div className="with-margin-top-10">We were unable to process your card details because:</div>
                    <ul className="">
                      {parseDetailedErrors(detailedErrors).map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  </div>
                )}

                {_.isEmpty(detailedErrors) && <div className="with-margin-top">{error}</div>}
                <div className="with-margin-top-10">
                  Please ensure the card details you provided are accurate and try again.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {submitChildren}
      {/* <button className={isSubmitting ? 'button-loading' : ''} disabled={isSubmitting}>
          {paymentText}
        </button> */}
      {/* {error && <div className="response response-error">{error}</div>} */}
      {/* <div className="disclaimer">Payment securely processed by Assembly Payments.</div> */}
    </form>
  );
}
