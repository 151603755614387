import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import paymentCollectionReducer from 'app/ducks/paymentCollection/reducer';
import cardReducer from 'app/ducks/creditCard/reducer';
import purchaseReducer from 'app/ducks/purchase/reducer';
import paymentRequestReducer from 'app/ducks/paymentRequest/reducer';

const config = {
  key: 'root',
  storage,
};

const appReducer = persistCombineReducers(config, {
  paymentCollection: paymentCollectionReducer,
  card: cardReducer,
  purchase: purchaseReducer,
  paymentRequest: paymentRequestReducer,
});

export default(state, action) => appReducer(state, action);
