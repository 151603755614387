import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


const propTypes = {
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  isActive: false,
  className: '',
};

class Loader extends Component {
  render() {
    return (
      <div className={classnames('loading-container', { hide: !this.props.isActive })}>
        <div className={classnames('loading', this.props.className)} />
      </div>
    );
  }
}
Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;
export default Loader;
