import Teammo from '@teammo/teammo-api';
import history from 'app/routes/history';

function TeammoApi() {
  return Teammo(ENVIRONMENT, null, null, null, false);
}

export {
  TeammoApi,
  history,
};
