import React, { Component } from 'react';
import T from 'prop-types';
import Isvg from 'react-inlinesvg';

// Utils
import { parseAmount, friendlyDate } from 'app/utils/parsers';

// Images
import PaymentReceivedIcon from 'app/images/icons/payment_received.svg';

export default class PaymentSuccessful extends Component {
  static propTypes = { paymentRequest: T.object, purchase: T.object };

  static defaultProps = { paymentRequest: {}, purchase: {} };

  paymentAmount = () => {
    if (this.props.paymentRequest.paymentCollectionType === 'team-manager') {
      return parseAmount(this.props.paymentRequest.amount);
    }
    return parseAmount(this.props.paymentRequest.amountWithFee);
  };

  render() {
    const { paymentRequest, purchase } = this.props;
    return (
      <div className="payment-container">
        <div className="row border-bottom">
          <div className="col col-12 payment-successful">
            <Isvg className="tick" src={PaymentReceivedIcon} />
            <h2 className="with-top-margin">Payment Received</h2>
          </div>
          <div className="col col-10 offset-1 payment-info">
            {`Payment has been sent directly to ${this.props.paymentRequest.requesterName} to pay for ${this.props.paymentRequest.description}.`}
          </div>
        </div>
        <div className="row with-top-margin">
          <div className="col">
            <h3>Receipt</h3>
          </div>
        </div>
        <div className="row">
          <div className="col col-6 narrow">Payment Date</div>
          <div className="col col-6 narrow">{friendlyDate(paymentRequest.purchaseDate || purchase.createdAt)}</div>
        </div>
        <div className="row">
          <div className="col col-6 narrow">Payment Reference</div>
          <div className="col col-6 narrow">{paymentRequest.purchaseReference || purchase.friendlyReference}</div>
        </div>
        {paymentRequest.teamMemberName !== null && (
          <div className="row">
            <div className="col col-6 narrow">Received from</div>
            <div className="col col-6 narrow">{paymentRequest.teamMemberName}</div>
          </div>
        )}
        <div className="row">
          <div className="col col-6 narrow">Paid Amount</div>
          <div className="col col-6 narrow">{this.paymentAmount()}</div>
        </div>
        <div className="row">
          <div className="col col-6 narrow">Payment Method</div>
          <div className="col col-6 narrow">{paymentRequest.purchaseDetails}</div>
        </div>
      </div>
    );
  }
}
// <div className="fix-bottom">
//   <div className="row no-padding">
//     <Button className="btn-primary btn-full-width btn-no-top-rounding" color="primary">
//       CLOSE
//     </Button>
//   </div>
// </div>

// <div className="row col">   <a href="">Email Receipt</a> </div>
