/* eslint-disable react/no-string-refs, no-case-declarations */
import React, { Component } from "react";
import T from "prop-types";
import classnames from "classnames";

// Components
import Button from "app/components/Button";
import Loader from "app/components/Loader";
import Checkbox from "app/components/Checkbox";
import CreditCardInputNew from "app/components/CreditCardInputNew";

// Utils
import { history } from "app/utils/redux/helpers";
import { parseAmount, parseCardInput } from "app/utils/parsers";

// const renderAssemblyJs = () => {
//   const script = document.createElement('script');
//   script.async = true;

//   if (ENVIRONMENT === 'production') {
//     script.src = 'https://hosted-fields.assemblypay.com/assembly.js';
//   } else {
//     script.src = 'https://hosted-fields.assemblypay.com/assembly.js';
//   }
//   document.body.appendChild(script);
// };

export default class PaymentInput extends Component {
  static propTypes = {
    referenceId: T.string,
    paymentRequestType: T.string,
    amount: T.number,
    paymentRequest: T.object,
    actions: T.object,
    card: T.object,
  };

  static defaultProps = {
    referenceId: undefined,
    paymentRequest: undefined,
    amount: undefined,
    paymentRequestType: undefined,
    card: undefined,
    actions: undefined,
  };

  state = {
    cardName: "",
    cardNumber: "",
    cardExpiry: "",
    cardCvv: "",
    errors: {},
    accepted: false,
    saveCardAllowed: true,
    isLoading: false,
    creditCardFormReady: false,
    providerProcessing: false,
    createCardError: false,
  };

  componentDidMount() {
    // renderAssemblyJs();
    if (window.DD_LOGS !== undefined) {
      DD_LOGS.logger.info("render-assembly-js", {
        env: ENVIRONMENT,
        secure_reference: this.props.referenceId,
      });
    }
    this.setupPayment(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.setupPayment(newProps);
    if (newProps.purchase && newProps.purchase.id) {
      if (newProps.purchase.status !== "invalid" && newProps.purchase.status !== "failed") {
        this.setState(Object.assign({}, this.state, { isLoading: false }));
        history.push(`${newProps.match.url}/successful`);
      } else {
        this.setState(Object.assign({}, this.state, { isLoading: false }));
        history.push(`${newProps.match.url}/failed`);
      }
    }
    if (newProps.card && !newProps.card.isLoading && newProps.card.failed) {
      this.setState(Object.assign({}, this.state, { isLoading: false }));
    }
  }

  setupTeamManagerPayment = (props) => {
    if (props.paymentRequestType === "team-manager" && props.paymentRequest.person !== undefined) {
      props.actions.createOrgCreditCardToken({
        person: { id: props.paymentRequest.person.id },
      });
    }
  };

  setupOrgCustomerPayment = (props) => {
    if (props.paymentRequestType === "person" && props.paymentRequest.person !== undefined) {
      props.actions.createOrgCreditCardToken({
        person: { id: props.paymentRequest.person.id },
      });
    }
  };

  setupConsumerPayment = (props) => {
    if (
      props.paymentRequestType === "consumer" &&
      props.paymentRequest.teamMember !== undefined &&
      props.paymentRequest.consumer !== undefined
    ) {
      props.actions.createTeamCreditCardToken({
        secureReference: props.referenceId,
        teamMember: {
          id: props.paymentRequest.teamMember.id,
        },
        consumer: { id: props.paymentRequest.consumer.id },
      });
    }
  };

  setupPayment = (props) => {
    if (
      (props.card.creditCardToken === undefined || props.card.creditCardToken.cardToken === undefined) &&
      !props.card.failed &&
      props.paymentRequest &&
      !props.card.isLoading
    ) {
      this.setupConsumerPayment(props);
      this.setupTeamManagerPayment(props);
      this.setupOrgCustomerPayment(props);
    }
  };

  allowSaveCard = () => {
    this.setState({
      saveCardAllowed: !this.state.saveCardAllowed,
    });
  };

  acceptTerms = () => {
    if (window.DD_LOGS !== undefined) {
      DD_LOGS.logger.info("terms-and-conditions", {
        env: ENVIRONMENT,
        secure_reference: this.props.referenceId,
      });
    }
    this.setState({
      accepted: !this.state.accepted,
    });
  };

  validateCard = () => {
    if (window.DD_LOGS !== undefined) {
      DD_LOGS.logger.info("validate-card", {
        env: ENVIRONMENT,
        secure_reference: this.props.referenceId,
        errors: this.state.errors,
      });
    }

    return !(
      this.state.errors.cardName ||
      this.state.errors.number ||
      this.state.errors.expiry ||
      this.state.errors.cvv
    );
  };

  validateCardName = () => {
    const validationErrors = {};

    validationErrors.cardName = !this.state.cardName || this.state.cardName.length < 2;
    this.setState({ errors: validationErrors });
  };

  updateCardErrors = (errors) => {
    this.setState({
      errors: { ...this.state.errors, ...errors },
    });
  };

  // handleCardNameChange = (e) => {
  //   if (this.state.errors.cardName) {
  //     this.validateCardName();
  //   }
  //   this.setState({
  //     backendErrors: undefined,
  //     cardName: e.target.value,
  //   });
  // };

  // handleCardNumberChange = (e) => {
  //   this.validateCardName();
  //   this.setState({
  //     backendErrors: undefined,
  //     cardNumber: e.target.value,
  //   });
  // };

  // handleCardExpiryChange = (e) => {
  //   this.setState({
  //     backendErrors: undefined,
  //     cardExpiry: e.target.value,
  //   });
  // };

  // handleCardCVCChange = (e) => {
  //   this.setState({
  //     backendErrors: undefined,
  //     cardCvv: e.target.value,
  //   });
  // };

  handleChange = (fieldName, valid) => {
    this.setState((previousState) => {
      const newState = {
        backendErrors: undefined,
        [fieldName]: valid,
      };
      return newState;
    });
  };

  handleFormIsReady = (isReady) => {
    this.setState({ creditCardFormReady: isReady });
  };

  submitConsumerPayment = async (createdCardToken) => {
    const cardDetails = {
      createdCardToken,
      secureReference: this.props.referenceId,
      teamMember: {
        id: this.props.paymentRequest.teamMember.id,
      },
      status: this.state.saveCardAllowed ? "active" : "inactive",
      consumer: { id: this.props.paymentRequest.consumer.id },
    };
    if (window.DD_LOGS !== undefined) {
      DD_LOGS.logger.info("submit-consumer-payment", {
        env: ENVIRONMENT,
        payload: {
          ...cardDetails,
          raw: { cardName: this.state.cardName },
          number: undefined,
          expiryMonth: undefined,
          expiryYear: undefined,
          cvv: undefined,
        },
      });
    }
    await this.props.actions.createTeamCreditCard(cardDetails);
    if (this.props.card.creditCardId) {
      await this.props.actions.createTeamPurchase({
        purchaseType: "payment-collection",
        description: `payment for: ${this.props.referenceId}`,
        amount: this.props.paymentRequest.amount,
        paymentCollection: {
          id: this.props.paymentRequest.id,
        },
        creditCard: {
          id: this.props.card.creditCardId,
        },
      });
    }
  };

  submitTeamManagerPayment = async (createdCardToken) => {
    const cardDetails = {
      createdCardToken,
      person: { id: this.props.paymentRequest.person.id },
      status: this.state.saveCardAllowed ? "active" : "inactive",
    };
    if (window.DD_LOGS !== undefined) {
      DD_LOGS.logger.info("submit-manager-payment", {
        env: ENVIRONMENT,
        payload: {
          ...cardDetails,
        },
      });
    }
    await this.props.actions.createOrgCreditCard(cardDetails);
    if (this.props.card.creditCardId) {
      await this.props.actions.createOrgPurchase({
        purchaseType: "team-manager-org-payment",
        description: `payment for: ${this.props.referenceId}`,
        amount: this.props.paymentRequest.amount,
        paymentCollection: {
          id: this.props.paymentRequest.id,
        },
        creditCard: {
          id: this.props.card.creditCardId,
        },
        organisation: {
          id: this.props.paymentRequest.organisation.id,
        },
      });
    }
  };

  submitOrgCustomerPayment = async (createdCardToken) => {
    const cardDetails = {
      createdCardToken,
      secureReference: this.props.referenceId,
      person: { id: this.props.paymentRequest.person.id },
      status: this.state.saveCardAllowed ? "active" : "inactive",
    };
    if (window.DD_LOGS !== undefined) {
      DD_LOGS.logger.info("submit-org-customer-payment", {
        env: ENVIRONMENT,
        payload: {
          ...cardDetails,
          raw: { cardName: this.state.cardName },
          number: undefined,
          expiryMonth: undefined,
          expiryYear: undefined,
          cvv: undefined,
        },
      });
    }
    await this.props.actions.createOrgCreditCard(cardDetails);
    if (this.props.card.creditCardId) {
      await this.props.actions.createOrgPurchase({
        purchaseType: "org-customer-payment",
        description: `payment for: ${this.props.referenceId}`,
        amount: this.props.paymentRequest.amount,
        paymentCollection: {
          id: this.props.paymentRequest.id,
        },
        creditCard: {
          id: this.props.card.creditCardId,
        },
        organisation: {
          id: this.props.paymentRequest.organisation.id,
        },
        person: {
          id: this.props.paymentRequest.person.id,
        },
      });
    }
  };

  handleIsSubmitting = (e) => {
    if (window.DD_LOGS !== undefined) {
      DD_LOGS.logger.info("pay-button-clicked", {
        env: ENVIRONMENT,
        secure_reference: this.props.referenceId,
      });
    }
    this.setState({ isLoading: true });
  };

  handleIsNotSubmitting = (e) => {
    this.setState({ isLoading: false });
  };

  handleSubmit = (e) => {
    // e.preventDefault();

    if (
      this.validateCard() &&
      this.props.card.creditCardToken &&
      !_.isEmpty(e.card_accounts) &&
      !_.isEmpty(e.card_accounts.id)
    ) {
      this.setState({ isLoading: true });
      if (this.props.paymentRequestType === "consumer") {
        this.submitConsumerPayment(e.card_accounts.id);
      } else if (this.props.paymentRequestType === "team-manager") {
        this.submitTeamManagerPayment(e.card_accounts.id);
      } else if (this.props.paymentRequestType === "person") {
        this.submitOrgCustomerPayment(e.card_accounts.id);
      }
    }
  };

  handleNewCardSubmitError = (e, asmPayload) => {
    e.preventDefault();
    if (!_.isEmpty(asmPayload?.errors)) {
      this.setState({
        providerProcessing: false,
        createCardError: true,
      });
      // history.push(`${newProps.match.url}/failed`);
      this.setupConsumerPayment(this.props);
      this.setupTeamManagerPayment(this.props);
      this.setupOrgCustomerPayment(this.props);
    }
  };

  handleCardAddingStatus = () => {
    this.setState({
      providerProcessing: true,
    });
  };

  renderButton = () => {
    const { errors } = this.state;
    const hasError = errors.cardName || errors.number || errors.expiry || errors.cvv;
    return (
      <div
        className={classnames("row no-padding fix-bottom-row", {
          "d-none": !this.state.accepted,
        })}
      >
        <div className="fix-bottom">
          <Button
            className="btn-primary btn-full-width btn-no-top-rounding"
            color="primary"
            type="submit"
            disabled={
              !this.state.cardName ||
              !this.state.cardNumber ||
              !this.state.cardExpiry ||
              !this.state.cardCvv ||
              !this.state.accepted ||
              !this.validateCard ||
              hasError
            }
            isLoading={this.state.isLoading || this.state.providerProcessing}
          >
            {`Pay ${parseAmount(this.props.amount)} now`}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { accepted, errors } = this.state;
    let creditCardUserId;
    const creditCardToken = (this.props.card.creditCardToken && this.props.card.creditCardToken.cardToken) || undefined;
    if (this.props.paymentRequestType === "consumer" && this.props.paymentRequest.consumer) {
      creditCardUserId = this.props.paymentRequest.consumer.id;
    } else if (this.props.paymentRequestType === "team-manager" && this.props.paymentRequest.person) {
      creditCardUserId = this.props.paymentRequest.person.id;
    } else if (this.props.paymentRequestType === "person" && this.props.paymentRequest.person) {
      creditCardUserId = this.props.paymentRequest.person.id;
    }

    const formIsLoading = _.isEmpty(creditCardUserId) || _.isEmpty(creditCardToken);
    const formHasLoaded = this.state.creditCardFormReady;
    return (
      <div className="payment-container-wrapper">
        <div className={classnames("payment-container", { "d-none": formHasLoaded })}>
          <div className="row card-info-container description">
            <div className="col col-12">{`Payment will be sent directly to ${this.props.paymentRequest.requesterName} to pay for ${this.props.paymentRequest.description}. You will be able to pay via SMS for future payments.`}</div>
          </div>
          <div className="row with-margin-top-extra">
            <div className="col">
              <Loader isActive className="grey" />
            </div>
          </div>
        </div>
        {!formIsLoading && (
          <div className={classnames("payment-container", { "d-none": !formHasLoaded })}>
            <div className="row card-info-container description">
              <div className="col col-12">{`Payment will be sent directly to ${this.props.paymentRequest.requesterName} to pay for ${this.props.paymentRequest.description}. You will be able to pay via SMS for future payments.`}</div>
            </div>

            <CreditCardInputNew
              isLoading={this.isLoading}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              handleFormIsReady={this.handleFormIsReady}
              handleIsSubmitting={this.handleIsSubmitting}
              handleIsNotSubmitting={this.handleIsNotSubmitting}
              handleSubmitError={this.handleNewCardSubmitError}
              handleCardAddingStatus={this.handleCardAddingStatus}
              secureReference={this.props.paymentRequest.secureReference}
              creditCardToken={creditCardToken}
              creditCardUserId={creditCardUserId}
              submitChildren={this.renderButton()}
            >
              <div className="row">
                <div className="col col-12 accept-terms">
                  <Checkbox checked={accepted} onClick={this.acceptTerms} />
                  <label onClick={this.acceptTerms} className="terms-label">
                    I have read and agree to the Teammo&nbsp;
                    <a rel="noopener noreferrer" target="_blank" href="https://www.teammo.com.au/company/policies">
                      terms and conditions
                    </a>
                  </label>
                </div>
              </div>
            </CreditCardInputNew>
          </div>
        )}
      </div>
    );
  }
}
