import React, { Component } from 'react';
import T from 'prop-types';
import Isvg from 'react-inlinesvg';

// Utils
import { formatApiError } from 'app/utils/parsers';

// Images
import PaymentFailedIcon from 'app/images/icons/payment_failed.svg';

export default class PaymentError extends Component {
  static propTypes = {
    match: T.object,
    backendErrors: T.object,
  };

  static defaultProps = {
    match: undefined,
    backendErrors: {},
  };

  render() {
    return (
      <div className="payment-container">
        <div className="row">
          <div className="col col-12 payment-failed">
            <Isvg className="tick" src={PaymentFailedIcon} />
            <h2 className="with-top-margin">Unexpected Error</h2>
          </div>
          <div className="col col-10 offset-1 text-center payment-info error-message">
            {formatApiError(this.props.backendErrors)}
          </div>
          <div className="col col-10 offset-1 payment-info error-instruction">
            Please contact Teammo support by:
            <li><strong>Live support chat (recommended)</strong> - click icon in the bottom right.</li>
            <li><strong>Email support</strong> - email <a href="mailto:support@teammo.com.au">support@teammo.com.au</a></li>
          </div>
          <div className="col col-10 offset-1 payment-info">
            Failure reference: <strong>{this.props.match.params.reference}</strong>
          </div>
        </div>
      </div>
    );
  }
}
