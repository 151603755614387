import React, { Component } from 'react';
import TeammoLogo from 'app/images/teammo-logo.svg';

export default class InvalidPageView extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="container h-100">
        <div className="card h-100">
          <div className="card-header text-center teammo-background">
            <img className="team-logo" alt="Teammo" src={TeammoLogo} />
          </div>
          <div className="card-body col">
            <h3>Sorry, page not found</h3>
          </div>
        </div>
      </div>
    );
  }
}
