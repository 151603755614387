import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loader from 'app/components/Loader';

// Styles


class Button extends Component {
  static propTypes = {
    active: PropTypes.bool,
    block: PropTypes.bool,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    outline: PropTypes.bool,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    getRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onClick: PropTypes.func,
    size: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    cssModule: PropTypes.func,
    icon: PropTypes.string,
    centerloader: PropTypes.bool,
  };

  static defaultProps = {
    color: 'secondary',
    tag: 'button',
    isLoading: false,
    centerloader: false,
    icon: undefined,
    cssModule: undefined,
    getRef: undefined,
    outline: undefined,
    disabled: undefined,
    block: undefined,
    active: undefined,
    size: undefined,
    onClick: undefined,
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  render() {
    const {
      active,
      block,
      className,
      cssModule,
      color,
      outline,
      size,
      tag: Tag,
      getRef,
      isLoading,
      centerloader,
      ...attributes
    } = this.props;

    return (
      <button
        ref={getRef}
        onClick={this.onClick}
        className={classnames('btn', this.props.className, {
          'btn-center-loader': this.props.centerloader,
        })}
        {...attributes}
      >
        <Loader isActive={this.props.isLoading} />
        <span
          className={classnames('align-middle', {
            'btn-transparent-text':
              this.props.centerloader && this.props.isLoading,
          })}
        >
          {!this.props.isLoading ? this.props.children : null}
        </span>
      </button>
    );
  }
}

export default Button;
