import React, { Component } from 'react';
import T from 'prop-types';

// Utils
import { parseAmount } from 'app/utils/parsers';

// Styles


export default class PaymentDetailsView extends Component {
  static propTypes = {
    paymentRequest: T.object,
  };

  static defaultProps = {
    paymentRequest: undefined,
  };

  paymentAmount = () => {
    if (this.props.paymentRequest.paymentCollectionType === 'team-manager') {
      return parseAmount(this.props.paymentRequest.amount);
    }
    return parseAmount(this.props.paymentRequest.amountWithFee);
  };
  render() {
    const { paymentRequest } = this.props;

    return (
      <div className="row card-info-container">
        <div className="col col-8 border-right border-bottom">
          <div className="font-weight-bold font-black">
            {paymentRequest.description}
          </div>
          <div>{paymentRequest.teamName}</div>
        </div>
        <div className="col col-4 border-bottom">
          <div>Amount</div>
          <div className="font-weight-bold font-black">
            {this.paymentAmount()}
          </div>
        </div>
      </div>
    );
  }
}
