import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { merge } from 'lodash';

// Components
import PaymentLayout from 'app/features/payment/PaymentLayout';

// Actions
import * as paymentRequestActions from 'app/ducks/paymentRequest/actions';
import * as paymentCollectionActions from 'app/ducks/paymentCollection/actions';
import * as cardActions from 'app/ducks/creditCard/actions';
import * as purchaseActions from 'app/ducks/purchase/actions';

const actionCreators = merge(
  paymentRequestActions,
  paymentCollectionActions,
  cardActions,
  purchaseActions,
);

function selector(state) {
  return {
    paymentCollection: state.paymentCollection,
    paymentRequest: state.paymentRequest,
    card: state.card,
    purchase: state.purchase,
  };
}

class PaymentView extends Component {
  render() {
    return (<PaymentLayout {...this.props} />);
  }
}

export default connect(selector, dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
}))(PaymentView);
