// @flow
import * as types from './actionTypes';

const initialState = {
  creditCardId: undefined,
  currentCard: undefined,
  creditCardToken: undefined,
  isLoading: false,
  failed: false,
  backendErrors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.CREATE_CREDIT_CARD_PENDING: {
      return {
        ...state,
        isLoading: true,
        creditCardId: undefined,
        failed: false,
        currentCard: undefined,
      };
    }
    case types.CREATE_CREDIT_CARD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        failed: false,
        currentCard: action.response,
        creditCardId: action.response.id,
      };
    }
    case types.CREATE_CREDIT_CARD_FAIL: {
      return {
        ...state,
        isLoading: false,
        failed: true,
        backendErrors: action.backendErrors,
      };
    }
    case types.CREATE_CREDIT_CARD_TOKEN_PENDING: {
      return {
        ...state,
        isLoading: true,
        // creditCardToken: undefined,
      };
    }
    case types.CREATE_CREDIT_CARD_TOKEN_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        creditCardToken: action.response,
      };
    }
    case types.CREATE_CREDIT_CARD_TOKEN_FAIL: {
      return {
        ...state,
        isLoading: false,
        failed: true,
        backendErrors: action.backendErrors,
      };
    }
    case types.CREATE_CREDIT_CARD_TOKEN_RESET: {
      return {
        ...state,
        isLoading: false,
        failed: false,
        backendErrors: {},
        creditCardToken: undefined,
        currentCard: undefined,
        currentCcreditCardIdard: undefined,
      };
    }
    default:
      return state;
  }
}
