import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Images
import TeammoLogo from 'app/images/teammo-logo.svg';

// Styles


export default class FullLoader extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
  };

  static defaultProps = {
    isActive: false,
  };

  render() {
    return (
      <div className={classnames('full-page-loading-container', {
          'hide-loader': !this.props.isActive,
        })}
      >
        <img className="loader-teammo-logo" alt="Teammo" src={TeammoLogo} />
      </div>
    );
  }
}
