/* eslint-disable no-nested-ternary, react/jsx-indent */
import React, { Component } from 'react';
import T from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import _ from 'lodash';

// Components
import FullLoader from 'app/components/FullLoader';
import PaymentOverview from 'app/features/payment/components/PaymentOverview';
import PaymentInput from 'app/features/payment/components/PaymentInput';
// import TeamManagerOrgPayment from 'app/features/payment/components/TeamManagerOrgPayment';
import PaymentSuccessful from 'app/features/payment/components/PaymentSuccessful';
import PaymentFailed from 'app/features/payment/components/PaymentFailed';
import PaymentError from 'app/features/payment/components/PaymentError';

// Images
import TeammoLogo from 'app/images/teammo-logo.svg';

// Styling


export default class PaymentLayout extends Component {
  static propTypes = {
    actions: T.object,
    match: T.object,
    paymentRequest: T.object,
    card: T.object,
    purchase: T.object,
  };
  static defaultProps = {
    actions: undefined,
    match: undefined,
    paymentRequest: undefined,
    card: undefined,
    purchase: undefined,
  };

  async componentWillMount() {
    const { actions, match } = this.props;
    const app = match.params.app;
    if (app === undefined) {
      let reference = match.params.reference;
      if (reference[reference.length - 1] === '.') {
        reference = reference.slice(0, -1);
      }
      if (reference) {
        await actions.getTeamPaymentRequest(reference);
      }
    } else if (app === 'o') {
      let reference = match.params.reference;
      if (reference[reference.length - 1] === '.') {
        reference = reference.slice(0, -1);
      }
      if (reference) {
        await actions.getOrgPaymentRequest(reference);
      }
    }
  }

  renderPaymentForm = () => {
    if (this.props.paymentRequest.currentPaymentRequest.paymentCollectionType === 'team-manager') {
      return (
        <PaymentInput
          match={this.props.match}
          paymentRequestType="team-manager"
          amount={this.props.paymentRequest.currentPaymentRequest.amount}
          actions={this.props.actions}
          referenceId={this.props.match.params.reference}
          paymentRequest={this.props.paymentRequest.currentPaymentRequest}
          card={this.props.card}
          purchase={this.props.purchase.currentPurchase}
        />
      );
    }
    if (this.props.paymentRequest.currentPaymentRequest.paymentCollectionType === 'person') {
      return (
        <PaymentInput
          match={this.props.match}
          paymentRequestType="person"
          amount={this.props.paymentRequest.currentPaymentRequest.amount}
          actions={this.props.actions}
          referenceId={this.props.match.params.reference}
          paymentRequest={this.props.paymentRequest.currentPaymentRequest}
          card={this.props.card}
          purchase={this.props.purchase.currentPurchase}
        />
      );
    }
    return (
      <PaymentInput
        match={this.props.match}
        paymentRequestType="consumer"
        amount={this.props.paymentRequest.currentPaymentRequest.amountWithFee}
        actions={this.props.actions}
        referenceId={this.props.match.params.reference}
        paymentRequest={this.props.paymentRequest.currentPaymentRequest}
        card={this.props.card}
        purchase={this.props.purchase.currentPurchase}
      />
    );
  };

  renderContent() {
    const { match } = this.props;

    if (!_.isEmpty(this.props.paymentRequest.backendErrors)) {
      return (
        <div className="card-body">
          <PaymentError
            backendErrors={_.merge({}, this.props.paymentRequest.backendErrors, this.props.card.backendErrors)}
            match={this.props.match}
          />
        </div>
      );
    }
    return (
      <div className="card-body">
        <PaymentOverview paymentRequest={this.props.paymentRequest.currentPaymentRequest} />
        <Switch>
          <Route
            exact
            path={['/:app/:reference/successful', '/:reference/successful']}
            render={() => (
              <PaymentSuccessful
                match={this.props.match}
                paymentRequest={this.props.paymentRequest.currentPaymentRequest}
                purchase={this.props.purchase.currentPurchase}
              />
            )}
          />
          <Route
            exact
            path={['/:app/:reference/failed', '/:reference/failed']}
            render={() => (
              <PaymentFailed
                actions={this.props.actions}
                match={this.props.match}
                paymentRequest={this.props.paymentRequest.currentPaymentRequest}
                purchase={this.props.purchase}
              />
            )}
          />
          <Route
            exact
            path={['/:app/:reference', '/:reference']}
            render={this.renderPaymentForm}
          />

          <Redirect from="*" to="/404" />
        </Switch>
      </div>
    );
  }

  render() {
    const loading = this.props.paymentRequest.isLoading;
    return (
      <div className="container h-100">
        <div className="card m-h-100">
          <div className="card-header text-center teammo-background">
            <img className="team-logo" alt="Teammo" src={TeammoLogo} />
          </div>
          <FullLoader isActive={loading} />
          {this.renderContent()}
        </div>
      </div>
    );
  }
}
