import { TeammoApi } from 'app/utils/redux/helpers';
import * as types from './actionTypes';

const getPaymentCollectionPending = () => ({ type: types.GET_PAYMENT_COLLECTION_PENDING });

const getPaymentCollectionSuccess = response => ({
  type: types.GET_PAYMENT_COLLECTION_SUCCESS,
  response,
});

const getPaymentCollectionFailed = error => ({
  type: types.GET_PAYMENT_COLLECTION_FAIL,
  backendErrors: error,
});

export const getOrgPaymentCollection = paymentId => async (dispatch) => {
  dispatch(getPaymentCollectionPending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.organisationPaymentCollections.show(paymentId);

  if (response) {
    dispatch(getPaymentCollectionSuccess(response.data));
  } else {
    const errorMsg = error && error.response ? error.response.data.errors : 'no payment information found';
    dispatch(getPaymentCollectionFailed(errorMsg));
  }
};

export const getTeamPaymentCollection = paymentId => async (dispatch) => {
  dispatch(getPaymentCollectionPending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.teamPaymentCollections.show(paymentId);

  if (response) {
    dispatch(getPaymentCollectionSuccess(response.data));
  } else {
    const errorMsg = error && error.response ? error.response.data.errors : 'no payment information found';
    dispatch(getPaymentCollectionFailed(errorMsg));
  }
};

export default null;
