/* eslint-disable import/no-extraneous-dependencies, global-require */

import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Redbox from 'redbox-react';
import configureStore from 'app/redux/store/configureStore';
import AppWrapper from 'app/containers/AppWrapper';

import 'app/styles/styles.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'app/utils/system/overrideLog';

const configuredStore = configureStore();
const { store, persistor } = configuredStore;
const rootEl = document.getElementById('app');

render(
  <AppContainer errorReporter={Redbox}>
    <AppWrapper store={store} persistor={persistor} />
  </AppContainer>,
  rootEl,
);

if (module.hot) {
  module.hot.accept('app/containers/AppWrapper', () => {
    const NextApp = require('app/containers/AppWrapper').default;

    render(
      <AppContainer errorReporter={Redbox}>
        <NextApp store={store} persistor={persistor} />
      </AppContainer>,
      rootEl,
    );
  });
}
