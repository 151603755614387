import { TeammoApi, history } from 'app/utils/redux/helpers';
import { createCreditCardTokenReset } from 'app/ducks/creditCard/actions';
import * as types from './actionTypes';

// CREATE PURCHASE
const createPurchasePending = () => ({ type: types.CREATE_PURCHASE_PENDING });

const createPurchaseSuccess = response => (
  { type: types.CREATE_PURCHASE_SUCCESS, response }
);

const createPurchaseFailed = error => (
  { type: types.CREATE_PURCHASE_FAIL, backendErrors: error }
);

export const createOrgPurchase = data => async (dispatch) => {
  dispatch(createPurchasePending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.organisationPurchases.create(data);
  if (error) {
    const errorMsg = error && error.response
      ? error.response.data.errors
      : 'payment not successful';
    dispatch(createPurchaseFailed(errorMsg));
    dispatch(createCreditCardTokenReset());
  } else {
    dispatch(createPurchaseSuccess(response.data));
    if (response.status === 'failed') {
      dispatch(createCreditCardTokenReset());
    }
  }
};
export const createTeamPurchase = data => async (dispatch) => {
  dispatch(createPurchasePending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.teamPurchases.create(data);
  if (error) {
    const errorMsg = error && error.response
      ? error.response.data.errors
      : 'payment not successful';
    dispatch(createPurchaseFailed(errorMsg));
    dispatch(createCreditCardTokenReset());
  } else {
    dispatch(createPurchaseSuccess(response.data));
    if (response.status === 'failed') {
      dispatch(createCreditCardTokenReset());
    }
  }
};

export const purchaseReset = secureReference => async (dispatch) => {
  history.push(`/${secureReference}`);
  dispatch({ type: types.PURCHASE_RESET });
};

// GET PURCHASE
const getPurchasePending = () => ({ type: types.GET_PURCHASE_PENDING });

const getPurchaseSuccess = response => (
  { type: types.GET_PURCHASE_SUCCESS, response }
);

const getPurchaseFailed = error => (
  { type: types.GET_PURCHASE_FAIL, backendErrors: error }
);

export const getOrgPurchase = purchaseId => async (dispatch) => {
  dispatch(getPurchasePending());
  const teammoApi = await TeammoApi();
  const [error, purchase] = await teammoApi.organisationPurchases.show(purchaseId);

  if (purchase) {
    dispatch(getPurchaseSuccess(purchase));
  } else {
    const errorMsg = error && error.response
      ? error.response.data.errors
      : 'no purchase information found';
    dispatch(getPurchaseFailed(errorMsg));
  }
};
export const getTeamPurchase = purchaseId => async (dispatch) => {
  dispatch(getPurchasePending());
  const teammoApi = await TeammoApi();
  const [error, purchase] = await teammoApi.teamPurchases.show(purchaseId);

  if (purchase) {
    dispatch(getPurchaseSuccess(purchase));
  } else {
    const errorMsg = error && error.response
      ? error.response.data.errors
      : 'no purchase information found';
    dispatch(getPurchaseFailed(errorMsg));
  }
};

export default null;
