import { TeammoApi } from 'app/utils/redux/helpers';
import * as types from './actionTypes';

// CREATE CREDIT CARD
const createCreditCardPending = () => ({
  type: types.CREATE_CREDIT_CARD_PENDING,
});

const createCreditCardSuccess = response => ({
  type: types.CREATE_CREDIT_CARD_SUCCESS,
  response,
});

const createCreditCardFailed = error => ({
  type: types.CREATE_CREDIT_CARD_FAIL,
  backendErrors: error,
});

const processAssemblyPayment = data =>
  new Promise((res, rej) => {
    // Create ASM credit card
    promisepay.createCardAccount(
      data.cardToken,
      {
        full_name: data.fullName,
        number: data.number,
        expiry_month: data.expiryMonth,
        expiry_year: data.expiryYear,
        cvv: data.cvc,
      },
      (response) => {
        if (window.DD_LOGS !== undefined) {
          DD_LOGS.logger.info('assembly-card-creation-response', {
            env: ENVIRONMENT,
            secure_reference: data.secureReference,
            response,
            payload: {
              token: data.cardToken,
              fullName: data.fullName,
            },
            card_token: data.cardToken,
          });
        }
        res(response);
      },
      (err) => {
        if (window.DD_LOGS !== undefined) {
          DD_LOGS.logger.info('assembly-card-creation-error', {
            env: ENVIRONMENT,
            secure_reference: data.secureReference,
            payload: {
              token: data.cardToken,
              fullName: data.fullName,
            },
            error: err,
          });
        }
        rej(err);
      },
    );
  });

export const createOrgCreditCard = data => async (dispatch) => {
  dispatch(createCreditCardPending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.organisationCreditCards.create({
    token: data.createdCardToken,
    status: data.status,
    person: { id: data.person.id },
  });

  if (response) {
    dispatch(createCreditCardSuccess(response.data));
  } else {
    dispatch(createCreditCardFailed(error.response.data.errors));
  }
};

export const createTeamCreditCard = data => async (dispatch) => {
  dispatch(createCreditCardPending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.teamCreditCards.create({
    token: data.createdCardToken,
    status: data.status,
    teamMember: { id: data.teamMember.id },
    consumer: { id: data.consumer.id },
  });

  if (response) {
    dispatch(createCreditCardSuccess(response.data));
  } else {
    dispatch(createCreditCardFailed(error.response.data.errors));
  }
};

// CREATE CREDIT CARD TOKEN
const createCreditCardTokenPending = () => ({
  type: types.CREATE_CREDIT_CARD_TOKEN_PENDING,
});

const createCreditCardTokenSuccess = response => ({
  type: types.CREATE_CREDIT_CARD_TOKEN_SUCCESS,
  response,
});

const createCreditCardTokenFailed = error => ({
  type: types.CREATE_CREDIT_CARD_TOKEN_FAIL,
  backendErrors: error,
});

export const createOrgCreditCardToken = data => async (dispatch) => {
  dispatch(createCreditCardTokenPending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.organisationCreateCreditCardTokens.create(data);
  if (window.DD_LOGS !== undefined) {
    DD_LOGS.logger.info('create-card-token-response', {
      env: ENVIRONMENT,
      payload: data,
      error,
      response,
    });
  }
  if (response) {
    dispatch(createCreditCardTokenSuccess(response.data));
  } else {
    const errorMsg =
      error && error.response
        ? error.response.data.errors
        : 'cannot generate card token';
    if (window.DD_LOGS !== undefined) {
      DD_LOGS.logger.info('assembly-card-token-error', {
        env: ENVIRONMENT,
        secure_reference: data.secureReference,
        error: errorMsg,
      });
    }
    dispatch(createCreditCardTokenFailed(errorMsg));
  }
};

export const createTeamCreditCardToken = data => async (dispatch) => {
  dispatch(createCreditCardTokenPending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.teamCreateCreditCardTokens.create(data);
  if (window.DD_LOGS !== undefined) {
    DD_LOGS.logger.info('create-card-token-response', {
      env: ENVIRONMENT,
      payload: data,
      error,
      response,
    });
  }
  if (response) {
    dispatch(createCreditCardTokenSuccess(response.data));
  } else {
    const errorMsg =
      error && error.response
        ? error.response.data.errors
        : 'cannot generate card token';
    if (window.DD_LOGS !== undefined) {
      DD_LOGS.logger.info('assembly-card-token-error', {
        env: ENVIRONMENT,
        secure_reference: data.secureReference,
        error: errorMsg,
      });
    }
    dispatch(createCreditCardTokenFailed(errorMsg));
  }
};

export const createCreditCardTokenReset = () => ({
  type: types.CREATE_CREDIT_CARD_TOKEN_RESET,
});
