import { TeammoApi, history } from 'app/utils/redux/helpers';
import * as types from './actionTypes';

const getPaymentRequestPending = () => ({ type: types.GET_PAYMENT_REQUEST_PENDING });

const getPaymentRequestSuccess = response => ({ type: types.GET_PAYMENT_REQUEST_SUCCESS, response });

const getPaymentRequestFailed = error => ({ type: types.GET_PAYMENT_REQUEST_FAIL, backendErrors: error });

export const getOrgPaymentRequest = secureReference => async (dispatch) => {
  dispatch(getPaymentRequestPending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.organisationPaymentRequestSummaries.show(secureReference);
  if (response) {
    if (response.data.status === 'pending') {
      dispatch(getPaymentRequestSuccess(response.data));
      history.push(`/${secureReference}`);
    } else if (response.data.status === 'collected') {
      dispatch(getPaymentRequestSuccess(response.data));
      history.push(`/${secureReference}/successful`);
    }
  } else {
    const errorMsg = error && error.response ? error.response.data.errors : 'no payment information found.';
    dispatch(getPaymentRequestFailed(errorMsg));
  }
};

export const getTeamPaymentRequest = secureReference => async (dispatch) => {
  dispatch(getPaymentRequestPending());
  const teammoApi = await TeammoApi();
  const [error, response] = await teammoApi.teamPaymentRequestSummaries.show(secureReference);

  if (response) {
    if (response.data.status === 'pending') {
      dispatch(getPaymentRequestSuccess(response.data));
      history.push(`/${secureReference}`);
    } else if (response.data.status === 'collected') {
      dispatch(getPaymentRequestSuccess(response.data));
      history.push(`/${secureReference}/successful`);
    }
  } else if (error.response && error.response.status === 404) {
    dispatch(getOrgPaymentRequest(secureReference));
  } else {
    const errorMsg = error && error.response ? error.response.data.errors : 'no payment information found.';
    dispatch(getPaymentRequestFailed(errorMsg));
  }
};


export default null;
