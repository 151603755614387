import _ from 'lodash';

const console = (function (oldCons) {
  return {
    debug(text) {
      if (oldCons.debug !== undefined) {
        oldCons.debug(text);
      } else {
        oldCons.log(text);
      }
    },
    log(text) {
      oldCons.log(text);
    },
    info(text) {
      oldCons.info(text);
    },
    warn(text) {
      if (
        !_.includes(text, 'UNSAFE')
        && !_.includes(text, 'cookie associated')
        && !_.includes(text, 'Violation')
      ) {
        oldCons.warn(text);
      }
    },
    error(text) {
      oldCons.error(text);
    },
  };
}(window.console));
window.console = console;
