import React, { Component } from "react";
import T from "prop-types";
import Isvg from "react-inlinesvg";

// Components
import Button from "app/components/Button";

// Utils
import { friendlyDate, formatApiError, formatAssemblyCreditCardError } from "app/utils/parsers";

// Images
import PaymentFailedIcon from "app/images/icons/payment_failed.svg";

export default class PaymentFailed extends Component {
  static propTypes = { match: T.object, purchase: T.object, actions: T.object };

  static defaultProps = {
    match: undefined,
    purchase: undefined,
    actions: undefined,
  };

  errorMessage = () => {
    const { purchase } = this.props;

    if (purchase && purchase.currentPurchase && purchase.currentPurchase.errorDescription) {
      return formatAssemblyCreditCardError(purchase.currentPurchase.errorDescription);
    } else if (purchase && purchase.backendErrors) {
      return formatApiError(purchase.backendErrors);
    }
    return "invalid data";
  };

  render() {
    const handleSubmit = async (e) => {
      let reference = this.props.match.params.reference;
      if (reference[reference.length - 1] === ".") {
        reference = reference.slice(0, -1);
      }
      e.preventDefault();
      this.props.actions.createCreditCardTokenReset();
      await this.props.actions.purchaseReset(reference);
    };
    const { purchase } = this.props;

    return (
      <div className="payment-container">
        <div className="row border-bottom">
          <div className="col col-12 payment-failed">
            <Isvg className="tick" src={PaymentFailedIcon} />
            <h2 className="with-top-margin">Payment Failed</h2>
          </div>
          <div className="col col-10 offset-1 payment-info error-message">
            <span>{this.errorMessage()}</span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>Reference</h3>
          </div>
        </div>
        <div className="row">
          <div className="col col-6 narrow">Payment Date</div>
          <div className="col col-6 narrow">
            {purchase && purchase.currentPurchase ? friendlyDate(purchase.currentPurchase.createdAt) : "N/A"}
          </div>
        </div>
        <div className="row">
          <div className="col col-6 narrow">Failure Reference</div>
          <div className="col col-6 narrow">
            {purchase && purchase.currentPurchase ? purchase.currentPurchase.friendlyReference : "N/A"}
          </div>
        </div>
        <div className="row no-padding fix-bottom-row">
          <div className="fix-bottom">
            <Button className="btn-primary btn-full-width btn-no-top-rounding" color="primary" onClick={handleSubmit}>
              TRY AGAIN
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
