/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import * as Sentry from '@sentry/browser';

import history from 'app/routes/history';
import App from './App';

// if (ENVIRONMENT === 'production') {
Sentry.init({
  dsn: SENTRY_DSN,
  release: GIT_VERSION,
  environment: ENVIRONMENT,
});
// }

interface IRootProps {
  store: any;
  persistor: any;
}
export default class Root extends React.Component<IRootProps, {}> {
  static defaultProps: IRootProps = {
    store: undefined,
    persistor: undefined,
  };

  render() {
    if (process.env.NODE_ENV !== 'production') {
      return (
        <Provider store={this.props.store}>
          <PersistGate loading={null} persistor={this.props.persistor}>
            <Router history={history}>
              <div className="root">
                <App key="teammo-app" />
              </div>
            </Router>
          </PersistGate>
        </Provider>
      );
    }
    return (
      <Provider store={this.props.store}>
        <PersistGate loading={null} persistor={this.props.persistor}>
          <Router history={history}>
            <App key="teammo-app" />
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}
