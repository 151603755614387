import React from 'react';
import Favicon from 'react-favicon';
import _ from 'lodash';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import * as Sentry from '@sentry/browser';
import Routes from 'app/routes';
import logo from 'app/images/favicon.png';
import { Route, Redirect, Switch } from 'react-router-dom';

import PaymentView from 'app/features/payment/PaymentView';

import InvalidPageView from 'app/features/InvalidPageView';

interface ITeammoAppProps {
  flags: any;
  location: any;
  loading: any;
  match: any;
}
interface ITeammoAppState {
  appUpdateAvailable: boolean;
}

class TeammoApp extends React.Component<ITeammoAppProps, ITeammoAppState> {
  notificationSystem = React.createRef();

  static defaultProps: ITeammoAppProps = {
    flags: undefined,
    ldClient: undefined,
    users: undefined,
    authentication: undefined,
    actions: undefined,
    loading: undefined,
    notifications: undefined,
    global: undefined,
    location: undefined,
    match: undefined,
  };

  state = {
    appUpdateAvailable: false,
  }

  updateApp = () => {
    console.log('Updating app');
    localStorage.removeItem('last-version');
    window.location.reload(true);
  }

  checkForUpdates = async () => {
    if (!this.state.appUpdateAvailable) {
      const response = await fetch('/manifest.json', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        cache: 'no-cache',
      });
      const data = await response.json();

      if (response.status === 200 && data !== undefined) {
        if (data.version !== undefined) {
          const lastVersion = data.version;
          const storedLastVersion = localStorage.getItem('last-version');
          if (storedLastVersion == null) {
            localStorage.setItem('last-version', lastVersion);
          } else if (storedLastVersion !== lastVersion) {
            this.setState({ appUpdateAvailable: true });
            console.log('App update available');
          }
        }
      }
    }
  }

  componentDidMount(): void {
    this.checkForUpdates();
    // Check every 5 minutes
    setInterval(this.checkForUpdates, 300000);
  }

  componentDidUpdate(prevProps): void {
    if (this.state.appUpdateAvailable && this.props.location.pathname !== prevProps.location.pathname) {
      this.updateApp();
    }
  }

  renderDebugVersion = () => {
    if (ENVIRONMENT === 'development' || ENVIRONMENT === 'staging') {
      return (
        <div
          className={classnames(
            'debug-version',
            {
              staging: ENVIRONMENT === 'staging',
            },
            {
              dev: ENVIRONMENT === 'development',
            },
          )}
        >
          {ENVIRONMENT === 'development' && 'Dev'}
          {ENVIRONMENT === 'staging' && 'Staging'}
          -
          {!_.isEmpty(GIT_VERSION) && GIT_VERSION.substring(0, 6)}
        </div>
      );
    }
    return null;
  };

  render(): React.ReactNode {
    return (
      <div className="h-100 page-container">
        {/* {this.renderIntercom()} */}
        {/* {this.renderFullstory()} */}
        {this.renderDebugVersion()}
        <Favicon url={logo} />
        <Switch>
          <Route path="/404" component={InvalidPageView} />
          <Route exact path="/:app/:reference/successful" component={PaymentView} />
          <Route exact path="/:app/:reference/failed" component={PaymentView} />
          <Route exact path="/:reference/successful" component={PaymentView} />
          <Route exact path="/:reference/failed" component={PaymentView} />
          <Route exact path="/:app/:reference" component={PaymentView} />
          <Route exact path="/:reference" component={PaymentView} />
          <Redirect from="*" to="404" />
        </Switch>
      </div>
    );
  }
}

export default withRouter((TeammoApp));
