// @flow
import * as types from './actionTypes';

const initialState = {
  collectionId: undefined,
  currentPaymentCollection: {},
  isLoading: false,
  backendErrors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_PAYMENT_COLLECTION_PENDING:
      return {
        ...state,
        isLoading: false,
        backendErrors: {},
        currentPaymentCollection: {},
        collectionId: undefined,
      };
    case types.GET_PAYMENT_COLLECTION_SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        currentPaymentCollection: action.response,
        collectionId: action.response.id,
      };
    }
    case types.GET_PAYMENT_COLLECTION_FAIL:
      return {
        ...state,
        isLoading: false,
        backendErrors: action.backendErrors,
      };

    default:
      return state;
  }
}
