/* eslint-disable no-console */
import _ from 'lodash';
import { format as currencyFormat } from 'currency-formatter';
import moment from 'moment';
import { parseFullName } from 'parse-full-name';
import dateFormat from 'dateformat';

moment.locale('en-au');
export function parseAmount(amount) {
  return currencyFormat(amount / 100, { code: 'AUD' });
}

export function parseTotal(payment, relevantPaymentCollections) {
  return currencyFormat(
    (payment.amount * relevantPaymentCollections.length) / 100,
    { code: 'AUD' },
  );
}

export function parsePending(payment, relevantPaymentCollections) {
  return relevantPaymentCollections.filter(t => t.status === 'pending').length;
}

export function parseCollected(payment, relevantPaymentCollections) {
  return relevantPaymentCollections.filter(t => t.status === 'collected')
    .length;
}

export function friendlyDate(dateString) {
  const date = new Date(dateString);
  const momentDate = moment(date);
  return momentDate.format('ddd D MMM YYYY - h:mm a');
}

export function formatApiError(errors) {
  if (!_.isEmpty(errors)) {
    let returnError = '';
    _.forIn(errors, (value, key) => {
      if (!_.isEmpty(value) && !_.isEmpty(key)) {
        const objectName = JSON.stringify(key)
          .replace(/[":0-9_-]/g, ' ')
          .trim();

        let errorMessage;
        if (value.constructor === Array) {
          errorMessage = JSON.stringify(value.join(', '))
            .replace(/[":0-9_-]/g, ' ')
            .trim();
        } else {
          errorMessage = value.replace(/[":0-9_-]/g, ' ').trim();
        }
        if (!_.isEmpty(objectName)) {
          returnError += `${objectName}: ${errorMessage} `;
        } else {
          returnError += `${errorMessage} `;
        }
      } else {
        returnError = 'Unknown error';
      }
    });
    return returnError;
  }
  return '';
}

export function formatAssemblyCreditCardError(errorMessage) {
  if (!_.isEmpty(errorMessage)) {
    if (errorMessage.includes('process the purchase transaction')) {
      return 'We were unable to process your payment. Please check your card details, try another card or call your card provider.';
    }
    return errorMessage
      .replace('base', '')
      .replace(/[[\]":0-9_-]/g, '')
      .trim();
  }
  return '';
}

export function formatExpiry(expiryDate, strSeparator, year) {
  let expiry = expiryDate;
  let separator = strSeparator;
  let fourDigitYear = year;

  if (separator === undefined || separator === null) {
    separator = ' / ';
  }
  if (fourDigitYear === undefined || fourDigitYear === null) {
    fourDigitYear = false;
  }
  // Separator must be a string
  if (typeof separator !== 'string') {
    separator = ' / ';
  }

  // Expiry must exist
  if (!expiry) {
    expiry = '';
  }
  const digitSearch = /\D/g;
  const expiryMaxLength = 2 + (fourDigitYear ? 4 : 2);
  // Replace non-numeric characters
  expiry = expiry.replace(digitSearch, '');
  // Only add the separator after the 3rd digit. Adding on the second can prevent backspaces in some cases (e.g. when
  // expiry is connected to a React state).
  if (expiry.length === 6) {
    expiry =
      expiry.slice(0, 2) + separator + expiry.slice(4, expiryMaxLength + 2);
  } else if (expiry.length >= 3) {
    expiry = expiry.slice(0, 2) + separator + expiry.slice(2, expiryMaxLength);
  }
  return expiry;
}

export function parseCardInput(cardName, cardNumber, expiry, cvc) {
  const cleanedCardName = cardName.replace('  ', ' ').trim();
  const cardNameAttrs = parseFullName(cleanedCardName);
  let formattedName;
  const firstName = cardNameAttrs.first;
  let lastName = cardNameAttrs.last;

  if (_.startsWith(lastName, 'On')) {
    if (_.isEmpty(firstName) || _.startsWith(firstName, 'On')) {
      lastName = `O${lastName}`;
    } else {
      lastName = `${firstName}`;
    }
  }

  if (_.isEmpty(firstName)) {
    formattedName = `${lastName} ${lastName}`;
  } else {
    formattedName = `${firstName} ${lastName}`;
  }

  if (formattedName.trim().split(' ').length === 1) {
    formattedName = `${formattedName.trim()} ${formattedName.trim()}`;
  }
  formattedName = formattedName.trim();

  // NUMBER
  const parsedCardNumber = cardNumber.replace(/\D/g, '');

  // EXPIRY
  const parsedExpiry = formatExpiry(expiry);
  const expiryMonth = parsedExpiry.split('/')[0].trim();
  const expiryYear = parsedExpiry.split('/')[1].trim();
  const cardDate = new Date(`${expiryMonth}/01/20${expiryYear}`);
  const parsedMonth = dateFormat(cardDate, 'mm');
  const parsedYear = dateFormat(cardDate, 'yyyy');

  // CVC
  const parsedCvc = cvc.replace(/\D/g, '');
  const cardResponse = {
    fullName: formattedName,
    number: parsedCardNumber,
    expiryMonth: parsedMonth,
    expiryYear: parsedYear,
    cvc: parsedCvc,
  };

  return cardResponse;
}
