import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Isvg from 'react-inlinesvg';
// Misc
import tick from 'app/images/icons/tick.svg';


const propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  checked: false,
  onClick: undefined,
};

class Checkbox extends Component {
  render() {
    return (
      <div
        className={classnames('checkbox-square', { checked: this.props.checked })}
        onClick={this.props.onClick}
      >
        {this.props.checked && <Isvg className="tick" src={tick} />}
      </div>
    );
  }
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;
export default Checkbox;
