import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import reduxImmutable from 'redux-immutable-state-invariant';

import rootReducer from '../reducer';

const middlewares = [
  ReduxThunk, reduxImmutable(),
];

const enhancer = compose(applyMiddleware(...middlewares));

export default function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, enhancer);
  const persistor = persistStore(store);
  persistor.purge();
  return { persistor, store };
}
