// @flow
import * as types from './actionTypes';

const initialState = {
  paymentRequestId: undefined,
  currentPaymentRequest: {},
  isLoading: true,
  backendErrors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_PAYMENT_REQUEST_PENDING:
      return {
        ...state,
        isLoading: true,
        backendErrors: {},
        currentPaymentRequest: {},
        paymentRequestId: undefined,
      };
    case types.GET_PAYMENT_REQUEST_SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        currentPaymentRequest: action.response,
        paymentRequestId: action.response.id,
      };
    }
    case types.GET_PAYMENT_REQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
        backendErrors: action.backendErrors,
      };

    default:
      return state;
  }
}
