// @flow
import * as types from './actionTypes';

const initialState = {
  purchaseId: undefined,
  currentPurchase: {},
  isLoading: false,
  backendErrors: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.PURCHASE_RESET:
    {
      return {
        ...state,
        purchaseId: undefined,
        currentPurchase: {},
        backendErrors: {},
        isLoading: false,
      };
    }
    case types.GET_PURCHASE_PENDING:
    {
      return {
        ...state,
        purchaseId: undefined,
        currentPurchase: {},
        backendErrors: {},
        isLoading: false,
      };
    }
    case types.GET_PURCHASE_SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        currentPurchase: action.response,
        purchaseId: action.response.id,
      };
    }
    case types.GET_PURCHASE_FAIL:
    {
      return {
        ...state,
        isLoading: false,
        backendErrors: action.backendErrors,
      };
    }
    case types.CREATE_PURCHASE_PENDING:
    {
      return {
        ...state,
        isLoading: true,
        currentPurchase: undefined,
        purchaseId: undefined,
      };
    }
    case types.CREATE_PURCHASE_SUCCESS:
    {
      return {
        ...state,
        isLoading: false,
        currentPurchase: action.response,
        purchaseId: action.response.id,
      };
    }
    case types.CREATE_PURCHASE_FAIL:
    {
      return {
        ...state,
        isLoading: false,
        backendErrors: action.backendErrors,
      };
    }
    default:
      return state;
  }
}
